<template>
  <div class="md:hidden sticky bottom-0 bg-white z-10 relative ">
    <div v-show="subTabisActive" class="fixed bg-black w-screen h-screen top-0 bg z-10 opacity-0"
      @click="(e) => hideNav(e)">
    </div>
    <div class="relative z-20 flex flex-col">
      <nav class="navigation" aria-label="Tabs">
        <ul>
          <li v-for="tab in navigation" :key="tab.name" :class="[tab.current ? 'active': '']">
            <a href="javascript:;"
              :aria-current="tab.current ? 'page' : undefined" @click="goToPage(tab)">
              <component :is="tab.icon"
              :cssclass="['icon h-6 w-6']"
              aria-hidden="true" />
              <span class="text">{{ tab.name }}</span>
            </a>
          </li>
          <div class="indicator"></div>
        </ul>
      </nav>
      <div v-for="tab in navigation" :key="tab.name">
        <div v-show="tab.menu && subTabisActive && tab.index === currentHoverTabIndex"
          class="left-0 flex flex-row items-stretch flex-wrap bg-primary-50 w-full z-10 rounded-t-xl" aria-label="Tabs">
          <a v-for="subTab in tab.menu" href="javascript:;"
            :class="[subTab.current ? 'border-primary-500 text-primary-600' : 'border-transparent text-gray-500', 'group flex items-center sm:inline-flex items-center px-1  font-normal text-sm flex-1 justify-center text-center h-16']"
            :aria-current="subTab.current ? 'page' : undefined" @click="goToSubPage(subTab)" :key="subTab.name">
            <component :is="subTab.icon"
              :cssclass="[subTab.current ? 'text-primary-500' : 'text-gray-400', 'mx-auto sm:-ml-0.5 sm:mr-2 h-6 w-6 block sm:inline']"
              aria-hidden="true" />
            <span class="block text-sm whitespace-nowrap">{{ subTab.name }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, shallowRef } from 'vue'

import HomeIcon from '../customIcons/HomeIcon.vue'
import ReportIcon from '../customIcons/ReportIcon.vue'
import BackOfficeIcon from '../customIcons/BackOfficeIcon.vue'
import CalendarIcon from '../customIcons/CalendarIcon.vue'
import ActionCenterIcon from '../customIcons/ActionCenterIcon.vue'

import ItemCollectionIcon from '../customIcons/ItemCollectionIcon.vue'
import StaffCollectionIcon from '../customIcons/StaffCollectionIcon.vue'
import CustomerCollectionIcon from '../customIcons/CustomerCollectionIcon.vue'
import { useAdminStore } from '@/store/pinia/admin'
import { useUserStore } from '@//store/pinia/user'

const staticNavigation = [
  { index: 0, name: 'Home', href: '/dashboard', current: true, icon: HomeIcon },
  { index: 1, name: 'Reports', href: '/reports', current: false, icon: ReportIcon },
  {
    index: 2,
    name: 'Office',
    href: '#',
    show_menu: false,
    current: false,
    icon: BackOfficeIcon,
    menu: [
      {
        index: 0,
        name: 'Items',
        description:
          'Services or Products which will be sold ',
        href: '/place/items', current: false, icon: ItemCollectionIcon
      },
      {
        index: 1,
        name: 'Staffs',
        description: "Manage your staff here",
        href: '/place/staffs', current: false, icon: StaffCollectionIcon
      },
      {
        index: 2,
        name: 'Roster',
        description: "Schedule your workplace roster",
        href: '/place/staffs/roster', current: false, icon: CalendarIcon
      },
    ],
  },
  {
    index: 3,
    name: 'Customers',
    href: '#',
    show_menu: false,
    current: false, icon: CustomerCollectionIcon,
    menu: [
      {
        index: 0,
        name: 'Customers',
        description:
          'Get a better understanding of where your traffic is coming from.',
        href: '/place/customers', current: false, icon: ItemCollectionIcon
      },
      {
        index: 1,
        name: 'Feedbacks',
        description:
          'Get a better understanding of where your traffic is coming from.',
        href: '#', current: false, icon: ItemCollectionIcon
      },
      {
        index: 2,
        name: 'Bookings',
        description:
          'Get a better understanding of where your traffic is coming from.',
        href: '#', current: false, icon: CalendarIcon
      },
    ],
  },
  { index: 4, name: 'Settings', href: '/settings', current: false, icon: ActionCenterIcon },
]

export default {
  components: {
  },
  setup() {

    const navigation = shallowRef(staticNavigation);
    const subTabisActive = ref(false);
    const currentHoverTabIndex = ref(null);
    const currentActiveSubTabIndex = ref(null);
    const adminStore = useAdminStore()
    const userStore = useUserStore()
    return {
      navigation,
      subTabisActive,
      currentHoverTabIndex,
      currentActiveSubTabIndex,
      adminStore,
      userStore,
    }
  },
  mounted() {
    const href = this.$route.href;
    const self = this;

    this.navigation.forEach(nav => {
      if (nav.href !== '#' && nav.href === href) {
        self.navigation[nav.index].current = true;
      } else if (nav.menu) {
        nav.menu.forEach(subMenu => {
          if (subMenu.href === href) {
            self.navigation[nav.index].current = true;
            self.navigation[nav.index].menu[subMenu.index].current = true;
          } else {
            self.navigation[nav.index].menu[subMenu.index].current = false;
          }
        });
      } else {
        self.navigation[nav.index].current = false;
      }
    });

    this.$forceUpdate();
  },
  methods: {
    async changePlace(place) {
      await this.adminStore.$patch({
        place: place
      })
      this.$router.go();
    },
    async signUserOut() {
      await this.userStore.signOut();
      this.$router.push({ name: 'Login', replace: true });
    },
    async goToPage(item) {
      if (item.menu) {
        if (this.currentHoverTabIndex === item.index) {
          this.subTabisActive = false;
          this.currentHoverTabIndex = null;
          this.currentActiveSubTabIndex = null;
        } else {
          this.subTabisActive = true;
          this.currentHoverTabIndex = item.index;
        }
        return;
      }

      const currentTabIndex = this.navigation.findIndex(nav => nav.current === true);
      if (currentTabIndex === item.index) {
        return;
      }

      this.navigation[currentTabIndex].current = false;
      this.navigation[item.index].current = true;

      if (item.href !== '#') {
        this.$router.push(item.href);
      }

      this.$forceUpdate();
    },
    async goToSubPage(subItem) {

      const currentTabIndex = this.navigation.findIndex(nav => nav.current === true);

      this.navigation[currentTabIndex].current = false;

      const self = this;
      this.navigation[this.currentHoverTabIndex].menu.reduce(item => {
        if (item) {
          self.navigation[self.currentHoverTabIndex].menu[item.index].current = false;
        }
      });
      this.navigation[this.currentHoverTabIndex].current = true;
      this.navigation[this.currentHoverTabIndex].menu[subItem.index].current = true;
      this.currentHoverTabIndex = null;
      this.currentActiveSubTabIndex = subItem.index;
      this.$router.push(subItem.href);
      this.$forceUpdate();
    },
    hideNav(e) {
      e.stopPropagation();
      e.preventDefault();
      /*
      if (this.currentHoverTabIndex >= 0) {
        this.navigation[this.currentHoverTabIndex].current = false;
        const self = this;
        this.navigation[this.currentHoverTabIndex].menu.reduce(item => {
          if (item) {
            self.navigation[self.currentHoverTabIndex].menu[item.index].current = false;
          }
        });
      }*/
      this.subTabisActive = false;
      this.currentActiveSubTabIndex = null;
      this.currentHoverTabIndex = null;
      this.$forceUpdate();
    }
  },
}
</script>
