<template><svg width="134" height="133" viewBox="0 0 134 133" fill="none" xmlns="http://www.w3.org/2000/svg" :class="[cssclass, '']">
    <g filter="url(#filter0_d)">
    <path d="M70.5606 112.041L60.5157 122.245H52.6343L67.4698 107.248V89.4679H73.4968V107.248L88.4869 122.245H80.76L70.5606 112.041Z" fill="url(#paint0_linear)"/>
    </g>
    <path opacity="0.5" d="M73.4967 89.9318H67.4697V95.1884H73.4967V89.9318Z" fill="#8991A5"/>
    <path d="M122.176 21.905H18.7905V85.1391H122.176V21.905Z" fill="#EAEEF9"/>
    <path opacity="0.2" d="M122.176 21.905H18.7905V27.1616H122.176V21.905Z" fill="#D6DDE8"/>
    <g filter="url(#filter1_d)">
    <path d="M127.276 10H14V21.7501H127.276V10Z" fill="#969EAE"/>
    </g>
    <g filter="url(#filter2_d)">
    <path d="M127.276 84.9844H14V90.0865H127.276V84.9844Z" fill="#969EAE"/>
    </g>
    <path d="M77.0099 50.0874H74.2722C74.0766 50.0874 73.6855 49.6963 73.6855 49.5008V34.6391C73.6855 34.248 73.8811 34.0525 74.2722 34.0525H77.0099C77.2054 34.0525 77.5965 34.4436 77.5965 34.6391V49.5008C77.401 49.6963 77.2054 50.0874 77.0099 50.0874Z" fill="#AAB2C5"/>
    <path d="M78.5741 50.0873H75.4453C75.2497 50.0873 75.0542 49.8917 75.0542 49.6962V42.852C75.0542 42.4609 75.2497 42.4609 75.4453 42.4609H78.5741C78.7696 42.4609 78.9652 42.6565 78.9652 42.852V49.6962C78.9652 49.8917 78.7696 50.0873 78.5741 50.0873Z" fill="#D6DCE8"/>
    <path d="M84.0499 50.0874H81.3122C81.1167 50.0874 80.7256 49.6963 80.7256 49.5007V39.1367C80.7256 38.7456 80.9211 38.55 81.3122 38.55H84.0499C84.2454 38.55 84.6365 38.9411 84.6365 39.1367V49.5007C84.6365 49.6963 84.2454 50.0874 84.0499 50.0874Z" fill="#AAB2C5"/>
    <path d="M91.089 50.0873H88.3513C88.1557 50.0873 87.7646 49.6962 87.7646 49.5006V42.852C87.7646 42.4609 87.9602 42.2654 88.3513 42.2654H91.089C91.2845 42.2654 91.6756 42.6565 91.6756 42.852V49.3051C91.6756 49.6962 91.2845 50.0873 91.089 50.0873Z" fill="#AAB2C5"/>
    <path d="M98.129 50.0873H95.3913C95.1958 50.0873 94.8047 49.6962 94.8047 49.5006V37.1811C94.8047 36.79 95.0002 36.5945 95.3913 36.5945H98.129C98.3245 36.5945 98.7156 36.9856 98.7156 37.1811V49.5006C98.7156 49.6962 98.5201 50.0873 98.129 50.0873Z" fill="#AAB2C5"/>
    <path d="M105.364 50.0872H102.627C102.431 50.0872 102.04 49.6962 102.04 49.5006V42.0698C102.04 41.6787 102.236 41.4831 102.627 41.4831H105.364C105.56 41.4831 105.951 41.8742 105.951 42.0698V49.5006C105.951 49.6962 105.755 50.0872 105.364 50.0872Z" fill="#AAB2C5"/>
    <path d="M112.404 50.0874H109.666C109.471 50.0874 109.08 49.6963 109.08 49.5007V39.1367C109.08 38.7456 109.275 38.55 109.666 38.55H112.404C112.599 38.55 112.991 38.9411 112.991 39.1367V49.5007C112.991 49.6963 112.795 50.0874 112.404 50.0874Z" fill="#AAB2C5"/>
    <path d="M85.6141 50.0874H82.2898C82.0942 50.0874 82.0942 49.8919 82.0942 49.6963V45.5898C82.0942 45.3943 82.2898 45.1987 82.2898 45.1987H85.6141C85.8096 45.1987 85.8096 45.3943 85.8096 45.5898V49.6963C86.0052 49.8919 85.8096 50.0874 85.6141 50.0874Z" fill="#D6DCE8"/>
    <path d="M92.849 50.0873H89.5246C89.3291 50.0873 89.3291 49.8917 89.3291 49.6962V47.7407C89.3291 47.5452 89.5246 47.3496 89.5246 47.3496H92.849C93.0445 47.3496 93.0445 47.5452 93.0445 47.7407V49.6962C93.0445 49.8917 93.0445 50.0873 92.849 50.0873Z" fill="#D6DCE8"/>
    <path d="M99.889 50.0872H96.5647C96.3691 50.0872 96.3691 49.8917 96.3691 49.6961V46.7629C96.3691 46.5674 96.5647 46.3718 96.5647 46.3718H99.889C100.085 46.3718 100.085 46.5674 100.085 46.7629V49.6961C100.28 49.8917 100.28 50.0872 99.889 50.0872Z" fill="#D6DCE8"/>
    <path d="M107.124 50.0874H103.995C103.8 50.0874 103.8 49.8918 103.8 49.6963V44.4165C103.8 44.2209 103.995 44.0254 103.995 44.0254H107.124C107.32 44.0254 107.32 44.2209 107.32 44.4165V49.6963C107.515 49.8918 107.32 50.0874 107.124 50.0874Z" fill="#D6DCE8"/>
    <path d="M114.555 50.0873H111.035C110.839 50.0873 110.839 50.0873 110.839 49.8918V48.914C110.839 48.7185 110.839 48.7185 111.035 48.7185H114.555C114.75 48.7185 114.75 48.7185 114.75 48.914V49.6962C114.75 49.8918 114.75 50.0873 114.555 50.0873Z" fill="#D6DCE8"/>
    <path d="M114.664 56.3232H73.6855V60.7773H114.664V56.3232Z" fill="white"/>
    <path d="M97.738 58.1049H86.1572V58.9957H97.738V58.1049Z" fill="#D6DCE8"/>
    <path d="M111.991 58.1049H107.537V58.9957H111.991V58.1049Z" fill="#D6DCE8"/>
    <path d="M114.664 64.3407H73.6855V68.7948H114.664V64.3407Z" fill="white"/>
    <path d="M94.1747 66.1223H86.1572V67.0131H94.1747V66.1223Z" fill="#D6DCE8"/>
    <path d="M111.991 66.1223H104.865V67.0131H111.991V66.1223Z" fill="#D6DCE8"/>
    <path d="M114.664 72.3582H73.6855V75.9215H114.664V72.3582Z" fill="white"/>
    <path d="M99.5197 73.249H86.1572V74.1398H99.5197V73.249V73.249Z" fill="#D6DCE8"/>
    <path d="M111.991 73.249H109.319V74.1398H111.991V73.249Z" fill="#D6DCE8"/>
    <path d="M52.4029 62.4423C50.2353 64.0308 47.5052 64.9243 44.5764 64.7588C38.2888 64.4279 33.2586 59.2323 33.0932 52.9281C32.9443 47.1368 36.8658 42.2225 42.2103 40.8492C42.9714 40.6506 43.5175 39.9722 43.5175 39.178V35.091C43.5175 34.4622 42.9549 33.9658 42.3261 34.0651C33.2421 35.5047 26.3091 43.447 26.4746 52.9612C26.6566 63.0876 34.9464 71.2947 45.0728 71.394C49.9044 71.4436 54.3058 69.6566 57.6647 66.6948C58.128 66.2811 58.128 65.5365 57.6482 65.1394L54.554 62.5085C53.9417 61.979 53.0648 61.9625 52.4029 62.4423Z" fill="white"/>
    <path d="M46.9922 35.091V39.1945C46.9922 39.9888 47.5382 40.6506 48.2994 40.8492C52.5684 41.9578 55.9273 45.3167 57.0194 49.5692C57.2179 50.3303 57.8963 50.8763 58.674 50.8763H62.7775C63.4063 50.8763 63.9027 50.3138 63.8034 49.685C62.5459 41.6765 56.192 35.3226 48.1835 34.0651C47.5548 33.9824 46.9922 34.4622 46.9922 35.091Z" fill="#AAB2C5"/>
    <path d="M57.0195 55.6749C56.8375 56.3864 56.5893 57.0813 56.275 57.7266C55.944 58.4381 56.126 59.282 56.7217 59.7784L59.849 62.4424C60.3288 62.856 61.0734 62.7402 61.4044 62.1942C62.5957 60.192 63.423 57.9417 63.8036 55.5425C63.9029 54.9137 63.4065 54.3677 62.7777 54.3677H58.6907C57.8965 54.3511 57.2181 54.8972 57.0195 55.6749Z" fill="#D6DCE8"/>
    <defs>
    <filter id="filter0_d" x="37.6343" y="79.4679" width="55.8526" height="52.7766" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="-5"/>
    <feGaussianBlur stdDeviation="5"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.2 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
    </filter>
    <filter id="filter1_d" x="0" y="0" width="133.276" height="31.7501" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="-4"/>
    <feGaussianBlur stdDeviation="5"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.15 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
    </filter>
    <filter id="filter2_d" x="0" y="74.9844" width="133.276" height="25.102" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="-4"/>
    <feGaussianBlur stdDeviation="5"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.15 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
    </filter>
    <linearGradient id="paint0_linear" x1="70.5634" y1="90.6065" x2="70.5634" y2="109.004" gradientUnits="userSpaceOnUse">
    <stop stop-color="#B0BACC"/>
    <stop offset="1" stop-color="#969EAE"/>
    </linearGradient>
    </defs>
    </svg>

</template>



<script>
export default {
  props: ['cssclass'],
}
</script>
