import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import * as Sentry from '@sentry/vue'
import { Vue as VueIntegration } from '@sentry/integrations'
import { createI18n } from 'vue-i18n'
import Dinero from '../node_modules/dinero.js/build/esm/dinero.js'
import Maska from 'maska'
import Toast, { POSITION } from 'vue-toastification'
import Pusher from 'pusher-js'
import Echo from 'laravel-echo'
import VueCookies from 'vue-cookies'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import LoadScript from 'vue-plugin-load-script'

import 'vue-toastification/dist/index.css'
import './assets/styles/main.scss'

import { registerSW } from 'virtual:pwa-register'

import App from './App.vue'
import en from './i18n/eng'
// import store from './store/vuex'
import { routes } from './routes'

import { createPinia } from 'pinia'

// import piniaPersist from 'pinia-plugin-persist'

//import { useUserStore } from "./store/pinia/user"

//import Spinner from '@/components/admin/Spinner.vue';

import { Device } from '@capacitor/device'

const logDeviceInfo = () => {
  Device.getId()
}

logDeviceInfo()

const updateSW = registerSW({
  onNeedRefresh() {
    if (confirm('Restart app for latest update?')) {
      updateSW()
    }
  },
  onOfflineReady() {},
})

const messages = {
  en,
}

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
  legacy: false,
})

const pinia = createPinia()
pinia.use(({ store }) => {
  const storageResult = JSON.parse(localStorage.getItem(store.$id))
  if (store.$id === 'POS' && storageResult) {
    delete storageResult.catalog
    delete storageResult.pinScreen
    delete storageResult.mx51Box
    storageResult.tyroBox = {
      status: null,
      pairing: null,
    }
  }

  if (store.$id === 'admin' && storageResult) {
    storageResult.isSetup = false
    storageResult.dashboard.stats = null
  }

  if (store.$id === 'App' && storageResult) {
    storageResult.isOnline = true
    storageResult.pageIsLoading = false
    storageResult.isSomethingLoading = {
      form: false,
      transaction_listing: false,
      checkin: false,
    }
  }

  store.$patch(storageResult)
  store.$subscribe(
    () => {
      // react to store changes mutation
      localStorage.setItem(store.$id, JSON.stringify(store.$state))
    },
    { detached: true }
  )
})

const router = createRouter({
  history: createWebHistory(),
  routes, // short for `routes: routes`
})

router.beforeEach(async (to, from, next) => {
  //const userStore = useUserStore()
  if (!window.$cookies.isKey('device_id')) {
    window.$cookies.set('device_id', (await Device.getId()).uuid)
  }

  if (to.name !== 'Login' && to.name !== 'POS-Login' && !window.$cookies.isKey('jwt'))
    // && !userState.jwt
    next({ name: 'Login' })
  else next()
})
const app = createApp(App)
app.use(VueCookies, { expires: '1825d' })
// app.use(store)
app.use(pinia)
app.use(router)
app.use(Maska)
app.use(LoadScript)
app.use(ElementPlus)
app.use(Toast, {
  position: POSITION.BOTTOM_CENTER,
})
app.use(i18n)

app.provide('format_price', function (value, number = null) {
  if (!number) {
    return Dinero({ amount: value }).toFormat()
  }
  return `$${nFormatter(value / 100, 2)}`
})

app.config.globalProperties.$filters = {
  format_price(value, number = null) {
    if (!number) {
      return Dinero({ amount: value }).toFormat()
    }
    return `$${nFormatter(value / 100, 2)}`
  },
  format_number_to_string(value, digits = 0) {
    return nFormatter(value, digits)
  },
}

//app.component('Spinner', Spinner);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

router.isReady().then(() => {
  app.mount('#app')
})

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://1f14bc39f5fe4fcc8afd0ad4180c449b@o723414.ingest.sentry.io/5781942',
    environment: import.meta.env.VITE_ENV === 'production' ? 'production' : 'development',
    integrations: [new VueIntegration({ Vue: app })],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
  })
}

window.Pusher = Pusher
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: '5ad088fc70e1dc06ceca',
  cluster: 'ap4',
  forceTLS: true,
  authEndpoint: import.meta.env.VITE_CHANNEL_ENDPOINT + '/broadcasting/auth',
})

function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value
    })
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0'
}
