import axios from 'axios'
import { useToast } from 'vue-toastification'
//import store from '@/store/vuex';
import { Device } from '@capacitor/device'
// import { date } from 'yup';

import { useAppStore } from './pinia/app'
import { useUserStore } from './pinia/user'
import { usePosStore } from './pinia/pos'
import { useAdminStore } from './pinia/admin'
import moment from 'moment'

const toast = useToast()
const apiEndPoint = import.meta.env.VITE_API_ENDPOINT

const apiCall = ({ method, endpoint, data, loadingType, sync }) => {
  const appStore = useAppStore()
  const userStore = useUserStore()
  const posStore = usePosStore()
  const adminStore = useAdminStore()

  appStore.setLoading(true, loadingType)

  let headers = null

  const $cookies = window.$cookies
  headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  if ($cookies.isKey('jwt')) {
    headers = {
      Authorization: 'Bearer ' + $cookies.get('jwt'),
      'X-REF-ID': $cookies.get('jwt_ref_id'),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }

  let place_id = null
  if (appStore.mode === 'POS') {
    place_id = posStore.place_id
  } else if (appStore.mode === 'ADMIN') {
    place_id = adminStore.place_id
  }

  endpoint = endpoint.replace('{place_id}', place_id)

  if (sync) {
    return axios(
      Object.assign(
        {
          method: method,
          url: apiEndPoint + endpoint,
          data: data,
        },
        headers ? { headers: headers } : {}
      )
    )
  }

  return axios(
    Object.assign(
      {
        method: method,
        url: apiEndPoint + endpoint,
        data: data,
      },
      headers ? { headers: headers } : {}
    )
  )
    .then(function (response) {
      appStore.setLoading(false, loadingType)

      return response.data
    })
    .catch(function (error) {
      console.log(error)
      console.log(error.response.status)
      if (error.response.status === 401) {
        userStore.signOut()
        window.location.reload()
      }

      appStore.setLoading(false, loadingType)

      if (error.response) {
        toast.error(error.response.data.message)
      } else if (error.request) {
        // client never received a response, or request never left
        toast.error(error.message)
      } else {
        // anything else
        toast.error(error.message)
      }
      return { status: false, message: error.message }
    })
}

export default {
  async login(data) {
    return await apiCall({
      method: 'POST',
      endpoint: '/login',
      data: data,
      loadingType: 'form',
    })
  },

  async posLogin(data) {
    data.append('device_id', window.$cookies.get('device_id'))
    return await apiCall({
      method: 'POST',
      endpoint: '/pos-login',
      data: data,
      loadingType: 'form',
    })
  },

  async checkPasscode(data) {
    return await apiCall({
      method: 'POST',
      endpoint: '/validate-passcode',
      data: data,
    })
  },

  /*
   *
   * MODE PAGE
   *
   */
  async getModePlaces() {
    return await apiCall({
      method: 'GET',
      endpoint: '/places',
    })
  },

  /*
   *
   * POS PAGE
   *
   */
  async validateNewGiftCertificate(barcode) {
    return await apiCall({
      method: 'GET',
      endpoint: '/pos/places/{place_id}/gift-certificates/validate/' + barcode,
      loadingType: 'pos',
    })
  },

  async getPosCatalog() {
    return await apiCall({
      method: 'GET',
      endpoint: '/pos/places/{place_id}/catalog',
      loadingType: 'pos',
    })
  },

  async getPosData() {
    return await apiCall({
      method: 'GET',
      endpoint: '/pos/places/{place_id}/data',
      loadingType: 'pos',
    })
  },

  async getCustomerList(search) {
    if (search) {
      return await apiCall({
        method: 'GET',
        endpoint: '/pos/places/{place_id}/customers?search=' + search,
      })
    }
    return await apiCall({
      method: 'GET',
      endpoint: '/pos/places/{place_id}/customers',
    })
  },

  async getTodayTransactionHistory() {
    return await apiCall({
      method: 'GET',
      endpoint: '/pos/places/{place_id}/sales/recent',
      loadingType: 'transaction_listing',
    })
  },

  getCustomerSales(customerId) {
    return apiCall({
      method: 'GET',
      endpoint: '/pos/places/{place_id}/customers/' + customerId + '/sales',
      loadingType: 'partial_form_sales',
    })
  },

  async updateCustomerById(data) {
    return await apiCall({
      method: 'PATCH',
      endpoint: `/places/{place_id}/customer/` + data._id,
      data: data,
    })
  },

  async deleteCustomerById(customerId) {
    return apiCall({
      method: 'DELETE',
      endpoint: '/places/{place_id}/customer/' + customerId,
    })
  },

  getCheckIns() {
    const start_date = moment().utc().startOf('week').format('YYYY-MM-DDTHH:mm:ss[Z]')
    const end_date = moment().utc().endOf('week').format('YYYY-MM-DDTHH:mm:ss[Z]')
    const result = apiCall({
      method: 'GET',
      endpoint: `/pos/places/{place_id}/customers/check-ins?start_date=${start_date}&end_date=${end_date}`,
      sync: true,
    })
    return result
  },

  async postAppointment(data) {
    return await apiCall({
      method: 'POST',
      endpoint: '/pos/places/{place_id}/appointments',
      data: data,
      loadingType: 'form',
    })
  },

  async patchAppointment(data) {
    return await apiCall({
      method: 'PATCH',
      endpoint: '/pos/places/{place_id}/appointments/' + data.id,
      data: data,
      loadingType: 'form',
    })
  },

  // SQUARE TERMINAL
  async submitSquareTerminalPayment(data) {
    return await apiCall({
      method: 'POST',
      endpoint: `/places/{place_id}/pos/create-terminal-checkout`,
      data: data,
    })
  },

  async cancelSquareTerminalPayment(data) {
    return await apiCall({
      method: 'POST',
      endpoint: `/places/{place_id}/pos/cancel-terminal-checkout`,
      data: data,
    })
  },

  async submitCashTransaction(data) {
    const posId = (await Device.getId()).uuid
    return await apiCall({
      method: 'POST',
      endpoint: `/pos/places/{place_id}/sales/${posId}`,
      data: data,
      loadingType: 'payment',
    })
  },

  async createNewCustomer(data) {
    return await apiCall({
      method: 'POST',
      endpoint: `/pos/places/{place_id}/customers`,
      data: data,
    })
  },

  async sendReceipt(data) {
    return await apiCall({
      method: 'POST',
      endpoint: `/pos/places/{place_id}/post-sales/${data.transaction_number}/send-receipt`,
      data: data,
    })
  },

  async getBookings(payload) {
    return await apiCall({
      method: 'GET',
      endpoint: `/pos/places/{place_id}/appointments?start_date=${payload.start_time}&end_date=${payload.end_time}`,
    })
  },

  async createBooking(data) {
    return await apiCall({
      method: 'POST',
      endpoint: `/places/{place_id}/appointments`,
      data: data,
    })
  },

  async pairingStatus(device_id) {
    const posId = window.$cookies.get('device_id')
    return await apiCall({
      method: 'GET',
      endpoint: `/pos/places/{place_id}/devices/${posId}/${device_id}/pairing-status`,
    })
  },

  async pairDevice(data) {
    data.pos_id = window.$cookies.get('device_id')
    return await apiCall({
      method: 'POST',
      endpoint: `/pos/places/{place_id}/devices/pair-device`,
      data: data,
      loadingType: 'pairing',
    })
  },

  async submitLinklyayment(data) {
    const posId = window.$cookies.get('device_id')
    return await apiCall({
      method: 'POST',
      endpoint: `/places/{place_id}/linkly/${posId}/create-terminal-checkout`,
      data: data,
    })
  },

  async cancelLinklyPayment(data) {
    const posId = window.$cookies.get('device_id')
    return await apiCall({
      method: 'POST',
      endpoint: `/pos/places/{place_id}/sales/${posId}/cancel-terminal-checkout/linkly`,
      data: data,
    })
  },

  async submitToTerminalWebhook(sessionId, data) {
    const posId = (await Device.getId()).uuid
    return await apiCall({
      method: 'POST',
      endpoint: `/linkly-cloud/session/{place_id}/${posId}/${sessionId}/app`,
      data: data,
    })
  },

  async issueLinklyRefund(refId) {
    const posId = (await Device.getId()).uuid
    return await apiCall({
      method: 'POST',
      endpoint: `/places/{place_id}/linkly/refund`,
      data: {
        reference_id: refId,
        posId,
      },
    })
  },

  async issueTyroRefund(refId, data) {
    const posId = (await Device.getId()).uuid
    return await apiCall({
      method: 'POST',
      endpoint: `/pos/places/{place_id}/sales/${posId}/refund/PAYMENTS_VIA_TYRO`,
      data: {
        reference_id: refId,
        posId,
        data,
      },
    })
  },

  async issueMx51Refund(refId, data) {
    const posId = (await Device.getId()).uuid
    return await apiCall({
      method: 'POST',
      endpoint: `/pos/places/{place_id}/sales/${posId}/refund/PAYMENTS_VIA_MX51`,
      data: {
        reference_id: refId,
        posId,
        data,
      },
    })
  },

  async updateTransactionResult(refId, data) {
    const posId = (await Device.getId()).uuid
    return await apiCall({
      method: 'POST',
      endpoint: `/pos/places/{place_id}/sales/${posId}/update/PAYMENTS_VIA_TYRO`,
      data: {
        reference_id: refId,
        posId,
        data,
      },
    })
  },

  async getTransactionStatus(cartSessionId) {
    const posId = (await Device.getId()).uuid
    return await apiCall({
      method: 'GET',
      endpoint: `/places/{place_id}/linkly/${posId}/transaction-status/${cartSessionId}`,
    })
  },

  async rePrintReceipt(transaction_number) {
    const posId = (await Device.getId()).uuid
    return await apiCall({
      method: 'GET',
      endpoint: `/places/{place_id}/linkly/${posId}/reprint-receipt/${transaction_number}`,
    })
  },

  async getCustomerByCheckIn(checkIn) {
    return await apiCall({
      method: 'POST',
      endpoint: `/pos/places/{place_id}/customers/get-check-in`,
      data: checkIn,
      loadingType: 'checkin',
    })
  },

  noShowCheckIn(checkIn) {
    return apiCall({
      method: 'POST',
      endpoint: `/pos/places/{place_id}/customers/set-check-in`,
      data: {
        id: checkIn.id,
        status: 'NO_SHOW',
      },
      loadingType: 'checkin',
    })
  },

  async sendLinklyKey(data) {
    return await apiCall({
      method: 'POST',
      endpoint: `/pos/places/{place_id}/post-sales/${data.transaction_number}/send-key`,
      data: {
        key: data.key,
      },
    })
  },

  async updateSalonSettings(data) {
    return await apiCall({
      method: 'PATCH',
      endpoint: `/pos/places/{place_id}/settings`,
      data: data,
    })
  },

  updatePaymentMethodSettings(data) {
    return apiCall({
      method: 'PATCH',
      endpoint: `/pos/places/{place_id}/settings/payment-method`,
      data: data,
    })
  },

  /*
   *
   * ADMIN PAGE
   *
   */
  async getDashboardStats(dateRange) {
    let data = {}
    if (dateRange) {
      data = {
        current_period_start_time: dateRange[0],
        current_period_end_time: dateRange[1],
      }
    }

    return await apiCall({
      method: 'POST',
      endpoint: '/places/{place_id}/dashboard-stats',
      data: data,
      loadingType: true,
    })
  },

  async getReports(dateRange) {
    let data = {}
    if (dateRange) {
      data = {
        current_period_start_time: dateRange[0],
        current_period_end_time: dateRange[1],
      }
    }

    return await apiCall({
      method: 'POST',
      endpoint: '/places/{place_id}/report',
      data: data,
    })
  },

  async getAdminCategories() {
    return await apiCall({
      method: 'GET',
      endpoint: '/places/{place_id}/catalog/categories',
    })
  },

  async getAdminItems() {
    return await apiCall({
      method: 'GET',
      endpoint: '/places/{place_id}/catalog/items',
    })
  },

  async getAdminItem(itemId) {
    return await apiCall({
      method: 'GET',
      endpoint: '/places/{place_id}/catalog/items/' + itemId,
      loadingType: 'form',
    })
  },

  async patchAdminItem(data) {
    return await apiCall({
      method: 'PATCH',
      endpoint: '/places/{place_id}/catalog/items/' + data.id,
      data: data,
      loadingType: 'form',
    })
  },

  async postAdminItem(data) {
    return await apiCall({
      method: 'POST',
      endpoint: '/places/{place_id}/catalog/items',
      data: data,
      loadingType: 'form',
    })
  },

  async getAdminDiscounts() {
    return await apiCall({
      method: 'GET',
      endpoint: '/places/{place_id}/catalog/discounts',
    })
  },

  async getAdminDiscount(itemId) {
    return await apiCall({
      method: 'GET',
      endpoint: '/places/{place_id}/catalog/discount/' + itemId,
    })
  },

  async patchAdminDiscount(data) {
    return await apiCall({
      method: 'PATCH',
      endpoint: '/places/{place_id}/catalog/discount/' + data._id,
      data: data,
    })
  },

  async postAdminDiscount(data) {
    return await apiCall({
      method: 'POST',
      endpoint: '/places/{place_id}/catalog/discount',
      data: data,
    })
  },

  async getAdminStaffs() {
    return await apiCall({
      method: 'GET',
      endpoint: '/places/{place_id}/employees',
    })
  },

  async getStaffActivities(name, dateRange) {
    console.log('data', {
      staff_name: name,
      current_period_start_time: dateRange.startDate,
      current_period_end_time: dateRange.endDate,
    })
    return await apiCall({
      method: 'POST',
      endpoint: '/places/{place_id}/staff-activities',
      data: {
        staff_name: name,
        current_period_start_time: dateRange.startDate,
        current_period_end_time: dateRange.endDate,
      },
    })
  },

  async getAdminStaff(staffId) {
    return await apiCall({
      method: 'GET',
      endpoint: '/places/{place_id}/employees/' + staffId,
    })
  },

  async patchAdminStaff(data) {
    return await apiCall({
      method: 'PATCH',
      endpoint: '/places/{place_id}/employees/' + data._id,
      data: data,
    })
  },

  async postAdminStaff(data) {
    return await apiCall({
      method: 'POST',
      endpoint: '/places/{place_id}/employees',
      data: data,
    })
  },

  async getAdminRosters() {
    return await apiCall({
      method: 'GET',
      endpoint: '/places/{place_id}/manage/roster',
    })
  },

  async getAdminRostersWithTime(data) {
    return await apiCall({
      method: 'GET',
      endpoint: '/places/{place_id}/manage/roster?start=' + data.start + '&end=' + data.end,
    })
  },

  async upsertAdminRoster(data) {
    return await apiCall({
      method: data._id ? 'PATCH' : 'POST',
      endpoint: '/places/{place_id}/manage/roster',
      data: data,
    })
  },

  async getAdminCustomers() {
    return await apiCall({
      method: 'GET',
      endpoint: '/places/{place_id}/customers',
    })
  },

  async adminGetList(resource, query = {}) {
    const params = new URLSearchParams(query).toString()
    return await apiCall({
      method: 'GET',
      endpoint: `/places/{place_id}/${resource}?${params}`,
    })
  },

  async adminGetItem(resource, itemId) {
    return await apiCall({
      method: 'GET',
      endpoint: `/places/{place_id}/${resource}/${itemId}`,
    })
  },

  async adminSetDetailItem(resource, method, formData) {
    let endpoint = `/places/{place_id}/${resource}`
    endpoint += method === 'POST' ? '' : `/${formData.id}`
    return await apiCall({
      method: method,
      endpoint: endpoint,
      data: formData,
      loadingType: 'form',
    })
  },
}
