export default {
  login: {
    title: 'Sign In',
    email: 'Email Address',
    password: 'Password',
    remember_me: 'Remember me',
    forgot_your_password: 'Forgot your password?',
    sign_in: 'Sign in',
    signing_in: 'Signing in...'
  },
  error: {
    this_field_is_required: 'This field is required',
    please_select_an_option: 'Please select an option',
    this_field_must_be_a_valid_email: 'This field must be a valid email',
    password_at_least_6: 'Password must be at least 6 characters',
  },
  Previous: 'Previous',
  'Let\'s start building your Business Profile': 'Let\'s start building your Business Profile',
  'This will help you get discovered by customers on Google Search and Maps': 'This will help you get discovered by customers on Google Search and Maps',
  '_': '',
  'By continuing, you’re agreeing to these Terms and conditions and Privacy Policy': 'By continuing, you’re agreeing to these Terms and conditions and Privacy Policy',
  'Business name': 'Business name',
  'Business category': 'Business category',
  'Do you want to add a location customers can visit, like a store or office??': 'Do you want to add a location customers can visit, like a store or office??',
  'This location will show up on Pocket Service app, Google Maps and Search when customers are looking for your business': 'This location will show up on Pocket Service app, Google Maps and Search when customers are looking for your business',
  
}