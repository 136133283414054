const Login = () => import('./screens/auth/Login.vue');
const PosLogin = () => import('./screens/auth/PosLogin.vue');
const ModePicker = () => import('./screens/misc/ModePicker.vue');

const PosScreen = () => import('./screens/pos/MainScreen.vue');

const AdminOverview = () => import('./screens/admin/Overview.vue');
const AdminDashboard = () => import('./screens/admin/Dashboard.vue');
const AdminSettings = () => import('./screens/admin/Settings.vue');
const AdminPosDeviceList = () => import('./screens/admin/devices/PosDeviceList.vue');


// Reports
const AdminReports = () => import('./screens/admin/Dashboard.vue');
const TransactionReports = () => import('./screens/admin/backoffice/transactions/TransactionList.vue');



const Places = () => import('./screens/admin/backoffice/Places.vue');
const CategoryList = () => import('./screens/admin/backoffice/categories/CategoryList.vue');
const CategoryDetail = () => import('./screens/admin/backoffice/categories/CategoryDetail.vue');
const ItemList = () => import('./screens/admin/backoffice/items/ItemList.vue');
const ItemDetail = () => import('./screens/admin/backoffice/items/ItemDetail.vue');
const DiscountList = () => import('./screens/admin/backoffice/discounts/DiscountList.vue');
const DiscountDetail = () => import('./screens/admin/backoffice/discounts/DiscountDetail.vue');
//const ProductDetail = () => import('./screens/admin/backoffice/product/Product.vue');
// const WizardNewPlace = () => import('./screens/admin/backoffice/wizard/NewPlace.vue');

const PositionList = () => import('./screens/admin/backoffice/staffs/PositionList.vue');
const PositionDetail = () => import('./screens/admin/backoffice/staffs/PositionDetail.vue');
const StaffList = () => import('./screens/admin/backoffice/staffs/StaffList.vue');
const StaffDetail = () => import('./screens/admin/backoffice/staffs/StaffDetail.vue');
//const StaffRoster = () => import('./screens/admin/backoffice/roster/Roster.vue');

const CustomerList = () => import('./screens/admin/customers/CustomerList.vue');
const CustomerFeedback = () => import('./screens/admin/customers/CustomerFeedback.vue');

export const routes = [
  { path: '/', component: Login, name: 'Login' },
  { path: '/pos-login', component: PosLogin, name: 'POS-Login' },
  { path: '/mode', component: ModePicker, name: 'ModePicker' },

  { path: '/pos', component: PosScreen, name: 'POS' },

  { path: '/overview', component: AdminOverview, name: 'Overview' },
  { path: '/dashboard', component: AdminDashboard, name: 'Dashboard' },

  // Reports
  { path: '/reports', component: AdminReports, name: 'Reports' },
  { path: '/reports/transactions', component: TransactionReports, name: 'TransactionReport' },
  { path: '/reports/activities', component: AdminReports, name: 'ActivityReports' },

  // Items
  { path: '/catalog/items', component: ItemList, name: 'Items' },
  { path: '/catalog/items/:item_id', component: ItemDetail, name: 'ItemDetail' },
  { path: '/catalog/categories', component: CategoryList, name: 'Categories' },
  { path: '/catalog/categories/:category_id', component: CategoryDetail, name: 'CategoryDetail' },
  { path: '/catalog/promotional-discounts', component: DiscountList, name: 'PromotionalDiscounts' },
  { path: '/catalog/promotional-discounts/:discount_id', component: DiscountDetail, name: 'DiscountDetail' },

  // Customers
  { path: '/customers', component: CustomerList, name: 'Customers' },
  { path: '/customers/appointments', component: CustomerList, name: 'CustomerAppointments' },
  { path: '/customers/feedbacks', component: CustomerFeedback, name: 'CustomerFeedbacks' },

  // StaffsPositionDetail
  { path: '/positions', component: PositionList, name: 'Positions' },
  { path: '/positions/:position_id', component: PositionDetail, name: 'PositionDetail' },
  { path: '/employees/', component: StaffList, name: 'Staffs' },
  //{ path: '/employees/shifts', component: StaffRoster, name: 'StaffRoster' },
  { path: '/employees/:staff_id', component: StaffDetail, name: 'StaffDetail' },

  // Action Centre
  { path: '/settings', component: AdminSettings, name: 'Settings' },
  { path: '/settings/link-machines', component: AdminSettings, name: 'SettingLinkMachines' },
  { path: '/settings/pos-devices', component: AdminPosDeviceList, name: 'SettingPosDevices' },
  


  /*
  { path: '/places', component: Places, name: 'Places' },
  { path: '/places/:place_id', component: Places, name: 'PlaceEdit' },
  { path: '/place/items', component: ItemList, name: 'Items' },
  { path: '/place/staffs', component: StaffList, name: 'Staffs' },
  { path: '/place/staffs/roster', component: StaffRoster, name: 'StaffRoster' },
  { path: '/place/staffs/:staff_id', component: StaffDetail, name: 'StaffDetail' },
  */
]