import { defineStore } from 'pinia'
import api from '../api'
import Dinero from 'dinero.js'
import moment from 'moment/moment'
import { useAppStore } from './app'
import { useCartStore } from './cart'
import { useToast } from 'vue-toastification'
import { Debugout } from 'debugout.js'
import metaData from '@/metadata.json'

let buildVersion = '1.00.000'
if (metaData.fullVersion) {
  buildVersion = metaData.fullVersion
}
export const usePosStore = defineStore('POS', {
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'POS',
        storage: localStorage,
      },
    ],
  },

  state: () => ({
    place_id: null,
    place: null,
    isReady: false,
    error: null,
    catalog: [],
    custom_catalog: [],
    discounts: [],
    staffs: [],
    roles: [],
    settings: [],
    customers: [],
    transactions: [],
    offlineTransactions: [],
    customerSales: null,
    checkins: [],
    bookings: [],
    booking_start_time: null,
    booking_end_time: null,
    linklyPairingStatus: false,
    linklyPairingSyncedAt: null,
    tyroBox: {
      pairingConfig: {
        mid: '',
        tid: '',
        integrationKey: null,
      },
      settings: {
        posVendorId: 'Weeklify!',
        posVendorName: 'Weeklify!',
        posVersion: buildVersion,
        deviceApiKey: 'TEST',
        enableIntegratedReceipt: false,
      },
      pairingStatus: {
        status: 'UNPAIRED',
        message: 'Unpaired',
      },
      status: null,
      pairing: null,
    },
    mx51Box: {
      pairingConfig: {
        posId: '332',
        eftposSerialNumber: process.env.NODE_ENV === 'development' ? '500-034-001' : '',
        eftposAddress: process.env.NODE_ENV === 'development' ? '192.168.1.50' : '',
        testMode: false, //process.env.NODE_ENV === 'development',
        autoAddressMode: true,
        tenant: {
          code: process.env.NODE_ENV === 'development' ? 'gko' : '',
          name: process.env.NODE_ENV === 'development' ? 'Gecko Demo Bank' : '',
        },
      },
      settings: {
        posVendorId: 'Weeklify!',
        posVersion: '1.5.0',
        deviceApiKey: 'testapikey',
        countryCode: 'AU',
        secureWebSockets: true,
        printMerchantCopy: false,
        promptForCustomerCopyOnEftpos: false,
        promptForMerchantCopyOnEftpos: false,
        signatureFlowOnEftpos: false,
        enableSurcharge: false,
      },
      pairingStatus: {
        status: 'UNPAIRED',
        message: 'Unpaired',
        refundId: null,
        refundCallback: null,
      },
      secrets: null,
      lastUpdated: null,
    },
    paymentFlow: 'PAYMENT_METHODS',
    pinScreen: {
      passcode: [],
      open: false,
      title: '',
      authenticated: false,
      callback: null,
    },
  }),

  actions: {
    async setUpPos() {
      //await this.getCatalog()
      //await this.getPosData()
      await Promise.all([this.getCatalog(), this.getPosData()])
      this.isReady = true
    },

    async togglePinModal({ toggle, title, callback }) {
      this.pinScreen.open = Boolean(toggle)
      this.pinScreen.title = title
      this.pinScreen.callback = callback
    },

    resetPinScreenCode() {
      this.pinScreen.passcode = []
    },

    async getCatalog() {
      try {
        const result = await api.getPosCatalog()
        this.catalog = result
      } catch (err) {
        console.log(err)
      }
    },

    async getPosData() {
      try {
        const result = await api.getPosData()
        this.staffs = result.employees
        this.roles = result.team_roles
        this.settings = result.settings
        this.discounts = result.discounts
        this.place.payment_methods = result.payment_methods

        const mx51 = this.hasMx51Payment
        if (mx51 && mx51.data?.config && mx51.data?.settings) {
          this.mx51Box.pairingConfig = mx51.data.config
          mx51.data.settings.posVersion = buildVersion
          this.mx51Box.settings = mx51.data.settings
          this
        }

        const tyro = this.hasTyroPayment
        if (tyro && tyro.data?.config && tyro.data?.settings) {
          this.tyroBox.pairingConfig = tyro.data.config
          this.tyroBox.settings = tyro.data.settings
          if (this.tyroBox.pairingConfig.integrationKey) {
            this.tyroBox.pairingStatus = {
              status: 'PAIRED',
              message: 'Paired',
            }
          }
        }
      } catch (err) {
        console.log(err)
      }
    },

    async getCustomerList() {
      try {
        const customersData = await api.getCustomerList()
        this.customers = customersData['data']
      } catch (err) {
        console.log(err)
      }
      return this.customers
    },

    async searchCustomer(query) {
      try {
        const customersData = await api.getCustomerList(query)
        this.customers = customersData['data']
      } catch (err) {
        console.log(err)
      }
      return this.customers
    },

    async updateCustomer(formData) {
      try {
        return await api.updateCustomerById(formData)
      } catch (err) {
        return false
      }
    },

    async deleteCustomer(customerId) {
      try {
        await api.deleteCustomerById(customerId)
        return true
      } catch (err) {
        console.log(err)
        return false
      }
    },

    async getCustomerSales(customerId) {
      try {
        this.customerSales = await api.getCustomerSales(customerId)
      } catch (err) {
        console.log(err)
      }
      return this.customerSales
    },

    async createNewCustomer(customerData) {
      const newCustomer = await api.createNewCustomer(customerData)

      let letter = newCustomer.first_name.toLowerCase()
      if (!this.customers[letter]) {
        this.customers[letter] = []
      }

      this.customers[letter].push(newCustomer)

      const cartStore = useCartStore()
      cartStore.$patch((state) => {
        state.cart.customer = newCustomer
      })

      return newCustomer
    },

    async getTransactionList() {
      try {
        this.transactions = await api.getTodayTransactionHistory()
      } catch (err) {
        console.log(err)
      }
      return this.transactions
    },

    async reSendReceipt(data) {
      const result = await api.sendReceipt(data)
      if (result.status === 'ok') {
        const toast = useToast()
        toast.success('Receipt has been sent to the customer', {
          timeout: 2000,
        })
      }
    },

    async issueLinklyRefund(ref_id) {
      const result = await api.issueLinklyRefund(ref_id)
      console.log(result)
    },

    async issueTyroRefund(transaction, refundAmount) {
      var requestParams = {
        amount: refundAmount.toString(), //transaction.checkout.grand_total.toString(),
        integratedReceipt: true,
        mid: this.tyroBox.pairingConfig.mid,
        tid: this.tyroBox.pairingConfig.tid,
        integrationKey: this.tyroBox.pairingConfig.integrationKey,
      }

      try {
        let merchantReceipt = null
        const self = this
        return new Promise((resolve, reject) => {
          window.iclient.initiateRefund(
            requestParams,
            {
              statusMessageCallback: function (status) {
                console.log(status)
              },
              questionCallback: function (question, answerCallback) {
                var buttons = question.options.map(function (option) {
                  return {
                    label: option,
                    callback: function () {
                      answerCallback(option)
                    },
                  }
                })
                console.log(question, buttons)
                if (question.isError) {
                  self.cart.displayText = 'ERROR'
                  self.cart.tyroBox.msg = question.text
                }
                self.cart.tyroBox.buttons = buttons
              },
              receiptCallback: function (receipt) {
                console.log(receipt)
                merchantReceipt = receipt.merchantReceipt
              },
              transactionCompleteCallback: (transactionData) => {
                console.log(transactionData)
                transactionData.merchantReceipt = merchantReceipt
                const toast = useToast()
                if (transactionData.result === 'APPROVED') {
                  api.issueTyroRefund(transaction.transaction_number, transactionData).then((result) => {
                    toast.success('Successfully issue a refund', {
                      timeout: 2000,
                    })
                    console.log(result)
                    resolve(true)
                  })
                } else {
                  toast.error('Declined', {
                    timeout: 2000,
                  })
                  api.updateTransactionResult(transaction.transaction_number, transactionData).then((result) => {
                    resolve(true)
                  })
                }
              },
            },
            false
          )
        })
      } catch (err) {
        console.log(err)
        if (err.toString().indexOf('Transaction already in progress')) {
          window.iclient.cancelCurrentTransaction()
        }
      }
    },

    async issueMx51Refund(transaction_number, transactionData) {
      const appStore = useAppStore()
      if (appStore.isOnline) {
        const result = await api.issueMx51Refund(transaction_number, transactionData)
        if (result) {
          await this.getTransactionList()
          const currentTransaction = this.transactions.find((t) => {
            return t.transaction_number === transaction_number
          })
          const cartStore = useCartStore()
          cartStore.cart.mx51Box.refundCallback(currentTransaction)
        }
        return result
      } else {
        //this.cart.status = 'COMPLETED'
        // cartData.offline_created_at = moment().toISOString()
        //posStore.addOfflineTransactions(cartData)
      }
    },

    async getBookings(date) {
      try {
        const booking_start_time = moment(moment(date, 'x').format('YYYY-MM-DD') + ' 00:00').format('YYYY-MM-DD HH:mm:ss')
        const booking_end_time = moment(moment(date, 'x').add(1, 'days').format('YYYY-MM-DD') + ' 00:00').format('YYYY-MM-DD HH:mm:ss')
        const result = await api.getBookings({
          start_time: booking_start_time,
          end_time: booking_end_time,
        })

        this.booking_start_time = booking_start_time
        this.booking_end_time = booking_end_time
        this.bookings = result.data
      } catch (err) {
        console.log(err)
      }
      return this.bookings
    },

    async createBooking(data) {
      const date = moment(data.date, 'x').format('YYYY-MM-DD')
      const dateTime = moment(date + ' ' + data.time, 'YYYY-MM-DD HH:mm A').format()
      data = Object.assign({}, data, {
        place_id: this.place_id,
        notify_customer: true,
        notify_method: 'SMS',
        date: date,
        date_time: dateTime,
        status: 'APPROVED',
      })

      const result = await api.createBooking(data)
      if (result.status === 'ok') {
        const toast = useToast()
        toast.success('New Booking is created.', {
          timeout: 2000,
        })
      }
    },

    addOfflineTransactions(transaction) {
      this.offlineTransactions.push(transaction)
    },

    clearPendingTransaction() {
      this.offlineTransactions = []
    },

    async getPairingStatus(device_id) {
      try {
        const result = await api.pairingStatus(device_id)
        console.log(result)
        this.linklyPairingStatus = result.status
        this.linklyPairingSyncedAt = result.last_sync
      } catch (err) {
        console.log(err)
      }
    },

    async pairDevice(device_id, pairCode) {
      try {
        const result = await api.pairDevice({ pair_code: pairCode, device_id })
        if (result.status === 'ok') {
          console.log(result)
          const toast = useToast()
          toast.success('EFTPOS device is ready for use.', {
            timeout: 2000,
          })
        }
      } catch (err) {
        console.log(err)
      }
    },

    async getTransactionStatus(cartSessionId) {
      try {
        const result = await api.getTransactionStatus(cartSessionId)
        if (result.status === 'ok') {
          return result
        }
      } catch (err) {
        return false
      }
    },

    async rePrintReceipt(transaction_number) {
      try {
        const result = await api.rePrintReceipt(transaction_number)
        if (result.status === 'ok') {
          return result
        }
      } catch (err) {
        return false
      }
    },

    async getCheckIns() {
      try {
        const result = await api.getCheckIns().then(function (response) {
          return response.data
        })
        console.log(result)
        this.checkins = result.checkins.data
        this.bookings = result.appointments.data
        //return result.data
      } catch (err) {
        return []
      }
    },

    noShow(checkIn) {
      api.noShowCheckIn(checkIn)
      this.checkins = this.checkins.filter(function (obj) {
        return obj.id !== checkIn.id
      })
    },

    async postAppointment(appointment) {
      const res = await api.postAppointment(appointment)
      this.getCheckIns()
      return res
    },

    tyroPairing(mid, tid) {
      const self = this
      this.tyroBox.status = 'inProgress'
      window.iclient.pairTerminal(mid, tid, function (response) {
        console.info('Tyro pairing:', response)
        self.tyroBox.pairing = response
        if (response.status === 'success') {
          self.tyroBox.pairingStatus = {
            status: 'PAIRED',
            message: 'Paired',
          }
          self.tyroBox.pairingConfig.integrationKey = response.integrationKey
          self.tyroBox.status = 'done'
          self.updateTyroSettings(self.tyroBox.pairingConfig, self.tyroBox.settings)
        } else if (response.status === 'inProgress') {
          self.tyroBox.pairingStatus = {
            status: 'IN_PROGRESS',
            message: 'Pairing in progress',
          }
        } else if (response.status === 'failure') {
          self.tyroBox.pairingStatus = {
            status: 'FAILED',
            message: response.message,
          }
          self.tyroBox.status = 'done'
        } else {
          self.tyroBox.pairingStatus.message = response.message
        }
      })
    },

    setMx51PairingStatus(status) {
      this.mx51Box.pairingStatus = status
    },

    setMx51PairingConfig(config) {
      this.mx51Box.pairingConfig = config
    },

    setMx51Settings(settings) {
      this.mx51Box.settings = Object.assign(this.mx51Box.settings, settings)
    },

    setMx51Secrets(secrets) {
      console.log('set secrets', secrets)
      const device = this.hasMx51Payment.devices[0] ?? null
      if (secrets === 1 && (device.data || this.hasMx51Payment.devices[0]?.data)) {
        this.mx51Box.secrets = this.hasMx51Payment.devices[0]?.data ?? device.data
        return
      }

      this.mx51Box.secrets = secrets === 1 ? null : secrets
    },

    updateMx51Settings(config, settings) {
      if (!config && !settings) {
        this.mx51Box.pairingConfig.eftposAddress = ''
        config = this.mx51Box.pairingConfig
      }

      api.updatePaymentMethodSettings({
        payment_method__id: this.hasMx51Payment._id,
        settings,
        config,
        device_secret: this.mx51Box.secrets,
      })
    },

    updateTyroSettings(config, settings) {
      api.updatePaymentMethodSettings({
        payment_method__id: this.hasTyroPayment._id,
        settings,
        config,
      })
    },

    updateGetTransactionState(e) {
      this.mx51Box.lastUpdated = new Date()
    },

    async patchAppointment(appointment) {
      const res = await api.patchAppointment(appointment)
      this.getCheckIns()
      return res
    },

    toggleSurcharge() {
      this.$patch((state) => {
        const surchargeSettingIndex = state.settings.findIndex((s) => s.name === 'TRANSACTION_SURCHAGE')
        const surchargeSettingValueIndex = state.settings.findIndex((s) => s.name === 'TRANSACTION_FEE')

        //const settings = [...this.place.settings]
        //settings[surchargeSettingIndex].value = state.mx51Box.settings.enableSurcharge

        this.settings[surchargeSettingIndex].value = state.mx51Box.settings.enableSurcharge
        if (state.mx51Box.settings.enableSurcharge) {
          state.settings[surchargeSettingValueIndex].value = state.mx51Box.settings.surchargeAmount + '%'
        }

        state.mx51Box.lastUpdated = new Date()
      })
    },

    validateNewGiftCertificate: async (barcode) => {
      return await api.validateNewGiftCertificate(barcode)
    },
  },

  getters: {
    categorySelect: (state) =>
      state.catalog.map((category) => {
        return { value: category._id, text: category.name }
      }),

    tyroDevice: (state) => {
      if (!state.place) return false
      return state.place.devices.find((device) => {
        return device.payment_method_id === 13 //TO DO: FIND BY METHOD NAME
      })
    },

    mx51Device: (state) => {
      if (!state.place) return false

      const mx51Payment = state.hasMx51Payment

      if (!mx51Payment) return false

      return state.place.devices.find((device) => {
        return device.payment_method_id === mx51Payment.id
      })
    },

    hasTyroPayment: (state) => {
      if (!state.place) return false
      return state.place.payment_methods.find((method) => {
        return method.type === 'PAYMENTS_VIA_TYRO'
      })
    },

    hasMx51Payment: (state) => {
      if (!state.place) return false
      return state.place.payment_methods.find((method) => {
        return method.type === 'PAYMENTS_VIA_MX51'
      })
    },

    hasLinklyPayment: (state) => {
      if (!state.place) return false
      return state.place.payment_methods.find((method) => {
        return method.type === 'MORE_PAYMENTS_VIA_LINKLY'
      })
    },

    attendingStaffList: (state) => {
      return [
        { value: -1, text: 'Any available staff' },
        ...state.staffs.map((s) => {
          return {
            value: s.nick_name,
            text: s.nick_name,
            info: 'Price is fixed when selecting this option on POS screen <br/>Enter the price below',
          }
        }),
      ]
    },

    sortedPaymentMethods: (state) => {
      if (!state.place) return []
      let paymentMethods = [...state.place.payment_methods]
      if (paymentMethods.length > 1) {
        paymentMethods.sort((a, b) => {
          return a.sort_id - b.sort_id
        })
      }
      return paymentMethods
    },

    surchargeRate: (state) => {
      const surchargeRateSetting = state.settings.find((s) => s.name === 'TRANSACTION_FEE')
      let surchargeRate = null
      if (surchargeRateSetting) {
        surchargeRate = surchargeRateSetting.value
      }
      return surchargeRate
    },
  },
})
