import { defineStore } from 'pinia'
import api from '../api'
import moment from 'moment/moment'
import axios from 'axios'

export const RESOURCE_TRANSACTION = 'report/transactions'
export const RESOURCE_CATALOG_ITEM = 'catalog/items'
export const RESOURCE_CATALOG_CATEGORY = 'catalog/categories'
export const RESOURCE_CATALOG_DISCOUNT = 'catalog/discounts'
export const RESOURCE_EMPLOYEE = 'employees'
export const RESOURCE_POSITION = 'positions'
export const RESOURCE_CUSTOMER = 'customers'
export const RESOURCE_CUSTOMER_FEEDBACK = 'customers/feedbacks'
export const RESOURCE_POS_DEVICE = 'pos-devices'

export const useAdminStore = defineStore('admin', {
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'admin',
        storage: localStorage,
      },
    ],
  },

  state: () => ({
    place_id: null,
    place: null,
    isSetup: false,
    dashboard: {
      stats: null,
      dateRange: {
        startDate: moment().format('DD MMM YYYY'),
        endDate: moment().format('DD MMM YYYY'),
      },
    },
    reports: {
      stats: null,
      dateRange: {
        startDate: moment().format('DD MMM YYYY'),
        endDate: moment().format('DD MMM YYYY'),
      },
    },
    items: null,
    discounts: null,
    categories: [],
    staffs: null,
    positions: [],
    customers: [],
    posDevices: [],
    //single view
    item: null,
    category: null,
    discount: null,
    position: null,
    staff: null,
    device: null,
    roster_view: null,
    view: {
      currentTabIndex: 0,
      currentTabName: null,
    },
    inBackgroundSince: null,
    passcode_check: null,
  }),

  actions: {
    async setupAdmin() {
      if (!this.isSetup) {
        axios.all([
          this.getList({
            resource: RESOURCE_CATALOG_CATEGORY,
            query: null,
          }),
          this.getList({
            resource: RESOURCE_POSITION,
            query: { per_page: 1000 },
          }),
        ])
        this.isSetup = true
      }
    },
    activateTab({ index, tabName }) {
      this.view.currentTabIndex = index
      this.view.currentTabName = tabName
    },
    async setItem(item) {
      this.item = item
    },
    async updateItem(item, mode) {
      this.item = Object.assign({}, this.item, item)
      if (mode === 'POST') {
        const result = await api.postAdminItem(this.item)
        this.item = result ? result : this.item
      } else if (mode === 'PATCH') {
        const result = await api.patchAdminItem(this.item)
        this.item = result ? result : this.item
      }
    },
    async getCustomers() {
      this.customers = await api.getAdminCustomers()
    },
    async getList(payload) {
      let result = await api.adminGetList(payload.resource, payload.query)

      switch (payload.resource) {
        case RESOURCE_CATALOG_ITEM:
          this.items = result.data
          break
        case RESOURCE_CATALOG_CATEGORY:
          this.categories = result.data
          break
        case RESOURCE_EMPLOYEE:
          this.staff = null
          this.staffs = result.data
          break
        case RESOURCE_POSITION:
          this.positions = result.data
          break
        case RESOURCE_CATALOG_DISCOUNT:
          this.discounts = result.data
          break
        case RESOURCE_POS_DEVICE:
          this.devices = result.data
          break
        default:
          break
      }

      return result
    },
    async getItem(payload) {
      let result = await api.adminGetItem(payload.resource, payload.itemId)

      switch (payload.resource) {
        case RESOURCE_CATALOG_ITEM:
          this.item = result
          break
        case RESOURCE_CATALOG_CATEGORY:
          this.category = result
          break
        case RESOURCE_EMPLOYEE:
          this.staff = result
          break
        case RESOURCE_POSITION:
          this.position = result
          break
        case RESOURCE_CATALOG_DISCOUNT:
          this.discount = result
          break
        case RESOURCE_POS_DEVICE:
          this.device = result
          break
        default:
          break
      }
      return result
    },
    async setDetailItem(payload) {
      switch (payload.resource) {
        case RESOURCE_CATALOG_CATEGORY:
          this.category = Object.assign({}, this.category, payload.formData)
          await api.adminSetDetailItem(payload.resource, payload.method, this.category)
          break

        case RESOURCE_CATALOG_DISCOUNT:
          this.discount = Object.assign({}, this.discount, payload.formData)
          await api.adminSetDetailItem(payload.resource, payload.method, this.discount)
          break

        case RESOURCE_EMPLOYEE:
          this.staff = Object.assign({}, this.staff, payload.formData)
          await api.adminSetDetailItem(payload.resource, payload.method, this.staff)
          break

        case RESOURCE_POSITION:
          this.position = Object.assign({}, this.position, payload.formData)
          await api.adminSetDetailItem(payload.resource, payload.method, this.position)
          break

        default:
          break
      }
    },

    async getReports() {
      try {
        const stats = await api.getReports(null)
        this.reports.stats = stats
      } catch (err) {
        console.log(err)
      }
    },

    async getDashboardStats() {
      try {
        const stats = await api.getDashboardStats(null)
        this.dashboard.stats = stats
      } catch (err) {
        console.log(err)
      }
    },

    async refreshReports(dateRange) {
      try {
        const stats = await api.getReports(dateRange)
        this.reports.stats = stats
      } catch (err) {
        console.log(err)
      }
    },

    async refreshDashboardStats(dateRange) {
      try {
        const stats = await api.getDashboardStats(dateRange)
        this.dashboard.dateRange = {
          startDate: dateRange[0],
          endDate: dateRange[1],
        }
        this.dashboard.stats = stats
      } catch (err) {
        console.log(err)
      }
    },

    async getStaffActivities(staffName) {
      try {
        const dateRange = this.dashboard.dateRange
        console.log(dateRange)
        return await api.getStaffActivities(staffName, dateRange)
      } catch (err) {
        console.log(err)
      }
    },
  },

  getters: {
    categorySelect: (state) =>
      state.categories.map((category) => {
        return { value: category.id, text: category.name }
      }),
    positionSelect: (state) =>
      state.positions.map((position) => {
        return { value: position.id, text: position.name }
      }),
  },
})
